.form--success {
    .container {
        height: 30vh;
        @apply flex flex-col justify-center text-center;
        h1 {
            @apply text-xl font-bold;
        }
        p {
            @apply mt-16 text-primary underline;
        }
    }
}

@media(max-width: theme('screens.laptop')) {
	.form--success {
        .container {
            h1 {
                @apply text-base;
            }
        }
    }
}