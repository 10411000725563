@tailwind base;
@tailwind components;
@tailwind utilities;

@import "base/base";
@import "base/font-families";

@import "layout/header";
@import "layout/footer";

@import "components/buttons";
@import "components/tp";

@import "layout/fragments/find-locations";
@import "layout/fragments/all-locations";
@import "layout/fragments/page-header-alt";
@import "layout/fragments/page-header";
@import "layout/fragments/locally-owned";
@import "layout/navigation";
@import "layout/mobile-nav";

@import "sections/section-experience-cards";
@import "sections/section-how-it-works";
@import "sections/section-page-intro";
@import "sections/section-yes";

@import "views/home";
@import "views/how-it-works";
@import "views/offer";
@import "views/meet-our-team";
@import "views/faq";
@import "views/location";
@import "views/about-us";
@import "views/contact";
@import "views/form-success";

@import "libs/flickity";