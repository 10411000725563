.visit--locations {
  @apply flex flex-col;
  .visit__title-container {
    @apply flex flex-row justify-center content-center h-24;
    
    .visit__image-container {
      @apply mr-5;
      img {
        @apply h-full;
      }
    }
    .title {
      @apply h-full flex flex-col justify-center;
      h2 {
        @apply text-4xl font-bold self-center;
      }
      p {
        @apply text-xl;
      }
    }
    .title--mobile {
      @apply hidden ;
      h2 {
        @apply text-3xl font-bold self-center;
      }
      p {
      }
    }
  }

  .visit__list-container {
    @apply flex flex-col items-center;
    h4 {
      @apply text-secondary font-semibold text-xl tracking-widest mt-16; 
    }
    hr {
      @apply w-8 mt-3;
    }
    .visit__list-grid {
      @apply grid grid-cols-3 justify-items-center;
      article {
        @apply flex flex-wrap flex-col mx-8;
  
        h3 {
          @apply text-2xl text-primary font-bold;
        }
        address {
          font-style: normal;
        }

        .btn {
          width: 8rem;
        }
        a:not(.btn) {
          @apply text-secondary mb-2;
        }
      }
    }
    .visit__list-grid.short_list {
      @apply grid-cols-2;
    }
    .visit__list-grid.long_list {
      @apply grid-cols-4;
      width: 90%;
    }
  }
}

@media(max-width: theme('screens.tablet')) {   
  .visit--locations {
    .visit__title-container {
      .title {
        @apply hidden;
      }
      .title--mobile {
        @apply h-full flex flex-col justify-center;
      }
    }
    
    .visit__list-container {
      h4 {
        @apply mt-6 text-base;
      }
      .visit__list-grid {
        @apply flex flex-col items-center;
        article {
          min-width: 220px;
          @apply overflow-visible;
          h3 {
            @apply overflow-visible;
          }
          .btn {
            @apply mb-8;
          }
        }
      }
      .visit__list-grid.short_list {
      }
    }
  }
}

@media(max-width: theme('screens.mobile')) {
  .visit--locations {
    .visit__title-container {
      .visit__image-container{
        @apply hidden;
      }
    }
  }
}