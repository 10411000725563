.section--experience-cards {
	@apply bg-grey;
	.container {
		@apply grid gap-6 grid-cols-2 py-6;
	}
}

.experience-card {
	@apply flex flex-col w-full bg-cover bg-center bg-no-repeat p-10 text-white font-thin;
	height: 600px;

	h1 {
		@apply text-4xl leading-14;
	}

	p {
		@apply text-2xl;

		&.long-quote {
			@apply text-base;
			line-height: 1.375;
		}
	}

	&.card--1 {
		@apply justify-end relative;
		background-image: url("../images/S2N_Home_Card1_2024.webp");

		h1 {
			@apply z-10;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(to bottom, transparent 50%, #222 100%);
		}
	}

	&.card--2 {
		background-image: url("../images/car2.png");
	}

	&.card--3 {
		@apply items-center justify-center text-center;
		background-image: url("../images/green-bg.png");

		h1 {
			@apply font-bold text-8xl;
		}

		.stars span {
			@apply text-5xl;
		}

		p {
			@apply py-5;
		}
	}

	&.card--4 {
		@apply bg-white text-black justify-center;
    
		.carousel-cell {
      @apply w-full flex flex-col px-4;
      height: 450px;
			.stars span {
        @apply text-3xl mb-4 text-secondary;
			}
    
      cite {
        @apply text-right;
      }
		}
    
		.flickity--reviews {
      .flickity-viewport {
      }
      /* no circle */
      .flickity-button {
        @apply relative px-14 py-2 top-0 left-0;
        border-radius: 25px;
        background: #D8D8D8;
      }
      .flickity-button:hover {
        background: #888888;
      }
      /* big previous & next buttons */
      .flickity-prev-next-button {
        @apply h-auto;
        width: 150px;
      }
      
      .flickity-prev-next-button.previous {
        @apply mr-5;
      }
      /* icon color */
      .flickity-button-icon {
        fill: white;
      }
      /* hide disabled button */
      .flickity-button:disabled {
        display: none;
      }
      .flickity-prev-next-button .flickity-button-icon {
        @apply relative left-0 w-full h-5;
      }
		}
	}
}

@media(max-width: theme('screens.laptop')) {
	.section--experience-cards {
		.container {
		}
	}

	.experience-card {
		@apply p-7;
		min-height: 400px;

		h1 {
			@apply text-3xl;
		}

		p {
			@apply text-base;

			&.long-quote {
				@apply text-sm;
				line-height: 1.125;
			}
		}

		&.card--2 {
			@apply pr-7;
		}
		&.card--3 {
			.stars span {
				@apply text-4xl;
			}
		}
		&.card--4 {
			.flickity--reviews {
        .flickity-prev-next-button {
          width: 8rem;
        }
      }
		}
	}
}

@media(max-width: theme('screens.tablet')) {
	.section--experience-cards {
		.container {
			@apply grid-cols-1;
		}
	}
}

@media(max-width: theme('screens.mobile')) {
	.experience-card {
		&.card--4 {
		@apply px-4;
		  .flickity--reviews {
			.flickity-prev-next-button {
			  width: 125px;
			}
		  }
		}

		p {
			&.long-quote {
				@apply text-sm leading-4;
			}
		}
	}
}