.section-pre-footer {
	.container {
		@apply flex flex-col items-center border-t border-grey py-14;
		h4 {
			@apply text-3xl mb-4;
		}
		p {
			@apply text-base tracking-tight mb-3;
		}
		nav ul {
			@apply flex mt-4;
		}
		
		img {
			@apply w-10 mx-3;
		}
	}
	
	
}


.footer--site {
  @apply bg-grey;
  
	.container {
    @apply flex flex-col justify-center text-center pt-12 pb-20;
	}
  
	.footer__links {
    @apply flex justify-center mb-5;
    
		ul {
      @apply flex flex-wrap justify-center;
		}
    
		li:not(:last-child)::after {
      @apply inline-block mx-4;
			content: '|';
		}
    
		a:hover {
      @apply underline;
		}
	}
  
	.footer__privacy {
    @apply flex justify-center;
    
		li {
      @apply text-sm;
		}
    
		li:not(:last-child):after {
      @apply inline-block mx-2;
			content: '|';
		}
    
		a:hover {
      @apply underline;
		}
	}
  
	.footer__copyright {
    @apply leading-5 text-xs pt-6;
		a:hover {
      @apply underline opacity-75;
		}
	}

  .cash-offer {
    @apply hidden;
  }
}


@media(max-width: theme('screens.tablet')) {
  .section-pre-footer {
    .container {
      h4 {
        @apply text-2xl;
      }
    }
  }

  .footer--site {
    .footer__links {
      ul {
        max-width: 450px;
      }
      
      li:not(:last-child)::after {
        content: '';
      }
      li:not(:nth-child(3n))::after {
        content: '|';
      }
    }

    .cash-offer {
      @apply block fixed bottom-0 w-full z-50 text-2xl tracking-wide;
      word-spacing: 2px;

      a {
        @apply bg-secondary border-secondary w-full rounded-none;

        &:hover {
          @apply text-white underline;
        }
      }
    }
  }
}

@media(max-width: theme('screens.mobile')) {
  .section-pre-footer {
    .container {
      @apply py-10;
    }
  }

  .footer--site {
    .footer__links {
      @apply mb-0;
      ul {
        @apply flex-col;
      }
      
      li {
        @apply mx-0 px-0;
      }

      li:not(:last-child)::after {
        @apply mx-0;
        content:'';
      }
      
    }
  }
}