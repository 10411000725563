.section-find-locations {
	.container {
		@apply flex justify-between items-center text-black pt-16 pb-10;
	}

	h2 {
		@apply font-medium text-5xl tracking-tight;
	}

	p {
		@apply mt-3 font-thin text-2xl;
	}

	form {
		@apply flex flex-nowrap gap-3;

		select {
			@apply bg-grey rounded text-lg border-solid border-grey pr-2 cursor-pointer;
			border-width: 12px;
		}

		input[type="submit"] {
			@apply bg-primary rounded text-lg text-white font-bold px-5 cursor-pointer;
		}
	}
}

@media(max-width: theme('screens.tablet')) {
	.section-find-locations {
		.container {
			@apply block;
		}

		h2 {
			@apply text-3xl;
		}

		p {
			@apply text-xl;
		}

		form {
			@apply mt-5;
		}
	}
}