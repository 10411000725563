.contact--form {
  h2 {
    @apply text-xl text-center;
    a {
      @apply text-secondary underline;
      &:hover {
        @apply opacity-75;
      }
    }
  }
  form {
    @apply flex flex-col;
    p {
      @apply pt-2;
      opacity: 40%;
    }
    label {
      @apply text-secondary font-bold tracking-widest pt-4;
    }
    .contact__input {
      @apply border-2 rounded-lg px-4 py-6;
      &:focus {
        @apply border-secondary outline-none;
      }
    }
    button {
      @apply w-48 mt-10 mx-auto;
    }
    textarea {
      @apply h-40;
    }
  }
}

@media(max-width: theme('screens.tablet')) {
  .contact--form {
    h2 {
      @apply text-xl text-left pt-4;
    }
  }
}