.section-page-intro {
	@apply text-xl leading-8;

	p {
		@apply leading-8;
	}

	h2 {
		@apply text-3xl font-bold;

		span {
			@apply text-primary;
		}
	}
}

@media(max-width: theme('screens.tablet')) {
	.section-page-intro {
		@apply text-center text-base;

		h2 {
			@apply text-2xl;
		}

		hr {
			@apply mx-auto;
		}
	}
}