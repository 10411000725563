.mobile--nav {
	@apply fixed top-0 right-0 w-0 bg-white overflow-hidden transition-all;
	height: 100vh;
	z-index: 9999;
	box-shadow: 10px 0px 30px rgba(0,0,0,0.2);
	
	&.shown {
	  width: 100%;
	}

	.mobile__nav_container {
        @apply absolute flex flex-col w-full;
        
        .nav__close {
			@apply flex w-full;
			.toggle-mobile-nav {
				@apply inline-block text-black ml-auto pr-5 my-7;
				span {
						@apply text-5xl;
				}
			}
        }

        nav {
            @apply text-xl text-black;
            ul {
                @apply flex flex-col;
                li.current-page > a {
                    @apply text-secondary font-medium;
                }
            }
        }
    }
}

.nav-item-mobile {
	@apply ml-8 pb-5;
	&:hover {
		@apply text-secondary underline;
	}
}

body.nav-shown {
	@apply overflow-y-hidden;
}