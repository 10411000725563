.section-employees {
	.container {
		@apply grid grid-cols-5 gap-20 py-24;
	}

	.employee {
		@apply text-center;

		picture {
			@apply block mb-4;

			img {
				@apply w-full;
			}
		}

		h2 {
			@apply font-bold text-xl text-primary;
		}

		p {
			@apply font-thin m-0;
		}
	}

	.employee:nth-child(odd) {
		h2 {
			@apply text-secondary;
		}
	}
}

@media(max-width: theme('screens.tablet')) {
	.section-employees {
		.container {
			@apply grid-cols-2 gap-8;
		}
	}
}

.section-page-intro {
  .container {
    .our-team {
      @apply uppercase tracking-widest font-bold text-xl mb-3 text-primary;
    }

    hr {
      @apply my-4 w-6;
    }
  }
}