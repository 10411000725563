.section-yes {
	@apply text-white bg-secondary;

	.container {
		@apply grid grid-cols-3 py-10;
	}

	.yes__slogan {
    h1 {
      @apply font-bold text-5xl;
    }
  }

	.text-rotator {
		@apply h-10 overflow-hidden;

		h2 {
			@apply flex flex-col py-1 font-semibold text-3xl;
      padding-top: .3rem;
		}
	}

	.text-rotator > * {
		@apply h-full;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
	}

	.yes__img {
    @apply flex content-center;
    img {
      @apply ml-auto self-center;
      width: 70%;
    }
  }
}

@media(max-width: theme('screens.desktop')) {
	.section-yes {
		.container {
      @apply grid-cols-4 pt-6 pb-5;
		}

    .yes__slogan {
      @apply flex justify-center col-span-2;
      h1 {
        @apply text-4xl;
      }
    }

		.text-rotator {
      @apply col-span-1;
			h2 {
        @apply text-xl;
			}
		}

    .yes__img {
      img {
        @apply col-span-1;
      }
    }
	}
}

@media(max-width: theme('screens.laptop')) {
	.section-yes {
		.container {
      @apply grid-cols-2;
		}

    .yes__slogan {
      @apply col-span-1;
    }

		.yes__img {
      @apply hidden;
    }
  }
}

@media(max-width: theme('screens.tablet')) {
	.section-yes {
		.container {
      @apply flex flex-col py-3;
		}

		.text-rotator {
      @apply mx-auto;
			h2 {
        @apply text-center;
			}
		}
  }
}
@media(max-width: theme('screens.mobile')) {
	.section-yes {
		.yes__slogan {
  		h1 {
        @apply text-3xl;
	  	}
    }
  }
}

