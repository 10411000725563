.section-page-header {
	.container {
		@apply text-center border-b border-gray-200 pb-12 mb-12;
		.heading--decorated {
			@apply relative inline-block text-5xl font-semibold;
			z-index: 0;
		
			&::after {
				@apply block absolute bottom-0 bg-gray-200;
				left: 50%;
				transform: translateX(-50%);
				width: 110%;
				height: 70%;
				content: '';
				z-index: -1;
			}
		}
	}
}




@media(max-width: theme('screens.tablet')) {
	.section-page-header {
		.container {
			.heading--decorated {
				@apply text-4xl;
			}
		}
	}
}

.heading--decorated {
	@apply relative inline-block text-5xl font-semibold;
	z-index: 0;

	&::after {
		@apply block absolute bottom-0 bg-gray-200;
		left: 50%;
		transform: translateX(-50%);
		width: 110%;
		height: 70%;
		content: '';
		z-index: -1;
	}
}

@media(max-width: theme('screens.tablet')) {
	.heading--decorated {
		@apply text-4xl;
	}
}