:root {
  --header-height: 110px;
  --header-height--mobile: 110px;
  --hero-height: calc(90vh - var(--header-height));
  --hero-height--mobile: calc(90vh - var(--header-height--mobile));
}

html,body {
	@apply bg-white h-full;
}

hr {
	@apply my-8;
}

body:not(.home) main {
	padding-top: calc(var(--header-height) + 5rem);
	padding-bottom: 3rem;
}

.heading--decorated {
	@apply relative inline-block text-4xl font-semibold text-center;
	z-index: 0;

	&::after {
		@apply block absolute bottom-0 bg-gray-200;
		left: 50%;
		transform: translateX(-50%);
		width: 110%;
		height: 70%;
		content: '';
		z-index: -1;
	}
}

@media(max-width: theme('screens.tablet')) {
	hr {
		@apply my-4;
	}

	body:not(.home) main {
		padding-top: calc(var(--header-height--mobile) + 4rem);
		padding-bottom: 2rem;
	}
}