.local-section-background {
    @apply flex items-center bg-cover bg-no-repeat bg-black bg-center;
    min-height: 750px;
    background-image: url("../images/S2N_Home_Local-Section@3x.jpg");

    .container {
        @apply flex flex-col h-full justify-center py-12 text-white;
        h2 {
            @apply font-medium text-4xl laptop:text-6xl tracking-tight;
        }
        article {
            @apply mt-4 mb-10;
            p {
                @apply text-xl mt-3;
            }
        }
    }
}

@media(max-width: theme('screens.laptop')) {
    .local-section-background {
        min-height: 500px;
        .container {
            article {
                p {
                    @apply text-sm;
                }
            }
        }
    }
}