.how-it-works {
	.container {
		@apply pb-12;
	}

  h2 {
    @apply font-bold text-primary text-center;
    font-size: 6vw;
  }

	.steps {
		@apply flex flex-row items-center;

		.steps__text {
      width: 55%;
			
			.step__number {
				@apply flex items-center font-medium uppercase text-primary text-sm mb-5;
				letter-spacing: 3px;

				.step__index {
					@apply inline-block rounded-full w-6 h-6 bg-primary text-white text-center p-1 pl-2 mr-3;
				}
			}

			.step__number--secondary {
				@apply text-secondary;
	
				.step__index {
					@apply bg-secondary;
				}
			}

			h3 {
				@apply font-bold text-4xl leading-none;
			}

			hr {
				@apply my-4 w-6;
			}

			.steps__emphasis {
				@apply text-lg font-medium pb-4;
			}

			p {
				a {
					@apply text-xl font-semibold text-primary underline;
				}
			}
		}

		.steps__image-container {
			@apply order-none mx-auto mb-6;
      width: 30%;

			img {
				width: 100%;
			}
		}
	}

	.arrows {
		@apply flex justify-center;
		.arrow__img {
      @apply mx-auto my-10;
		}
	}
}

@media(max-width: theme('screens.desktop')) {
  .how-it-works {
  
    h2 {
      font-size: 8vw;
    }
  }
}

@media(max-width: theme('screens.laptop')) {
  .how-it-works {
    
    .steps {
      .steps__image-container {
        width: 40%;
      }
    }
    .arrows {
      .arrow__img {
        height: 4vw;
      }
    }

  }
}

@media(max-width: theme('screens.tablet')) {
	.how-it-works {
    h2 {
      font-size: 10vw;
    }
    .steps {
      @apply flex flex-col;
      .steps__text {
        width: 85%;
        h3 {
          @apply text-2xl;
        }
        .steps__emphasis {
          @apply text-base;
        }
        p {
          @apply text-sm;
        }
      }
      .steps__image-container {
        @apply order-first;
        width: 50%
      }
    }
    .arrows {
      .arrow__img {
        height: 6vw;
      }
    }
	}
}
