.btn {
	@apply inline-block bg-primary border-primary text-white pb-3 px-5 border-2 font-semibold rounded-md text-center cursor-pointer;
	padding-top: .85rem;

	&:hover {
		@apply bg-white text-primary;
	}
}

.btn--secondary {
	@apply border-white bg-white bg-opacity-0;

	&:hover {
		@apply border-primary;
	}
}

.btn--small {
	@apply px-3 py-2 text-sm;
}
