.hero--background {
  @apply bg-black;
  height: var(--hero-height);
  margin-top: var(--header-height);

  .container {
    @apply flex flex-col h-full justify-end text-white pb-20;
    h2 {
      @apply font-medium text-8xl tracking-tighter;
    }

    p {
      @apply text-2xl;
    }

    .hero__links {
      @apply pt-10 mb-10;
    }
  }

  .hero__img {
    @apply absolute block top-0 left-0 h-full w-full z-0 object-cover object-center opacity-70;
    //height: var(--hero-height);
  }
}

.section--tp-widget {
  @apply bg-primary;

  .container {
    @apply text-white text-center pt-12;
    max-width: 900px;
  }

  #tradepending-container {
    @apply pt-10 pb-6 bg-primary px-6;

    #tradepending-select > button {
      background-color: #ff794f !important;
    }

    #tradepending-panel > p,
    #tradepending-panel > h3,
    #tradepending-panel > .tp-col > h2 {
      @apply hidden;
    }

    #tradepending-panel {
      p:first-child, h3 {
        //@apply hidden;
      }

      h3 {
        @apply font-bold text-5xl;
      }
    }
  }
}

.section--accutrade-widget {
  .offer__accutrade-container {
    padding-bottom: 3.5rem;
  }

}

@media(max-width: theme('screens.laptop')) {
  .hero--background {
    max-height: 800px;

    .container {
      @apply pb-0;
      h2 {
        @apply text-6xl;
      }

      .hero__links {
        a {
          @apply w-44 text-center mt-4;
        }
      }
    }

    .hero__img {
      max-height: 800px;
    }
  }
}

@media(max-width: theme('screens.tablet')) {
  .hero--background {
    margin-top: var(--header-height--mobile);
    height: var(--hero-height--mobile);
    max-height: 600px;

    .container {

      h2 {
        @apply text-5xl;
      }

      p {
        @apply text-xl;
      }

      .hero__links {
        a {
        }
      }
    }

    .hero__img {
      max-height: 600px;
    }
  }

  .section--tp-widget {
    #tradepending-container {
      @apply bg-primary;
      #tradepending-panel {
        h3 {
          @apply text-white text-3xl;
        }

        p {
          @apply text-white;
        }

        #trade-pending-widget {
          @apply mt-4;
        }
      }
    }
  }
}

@media(max-width: theme('screens.mobile')) {
  .hero--background {
    .container {
      h2 {
      }

      .hero__links {
        a {
        }
      }
    }

    .hero__img {
    }
  }
}