.section-location-map {
	@apply mb-10;

	.container {
		@apply grid grid-cols-6 gap-x-10;
	}

	.location__map {
		@apply col-start-1 col-end-5;

		iframe {
			@apply w-full;
			height: 500px;
		}
	}

	.location__details {
		@apply col-start-5 col-end-7;

		h2 {
			@apply font-bold text-2xl mb-3;
		}

		address {
			@apply mb-5;
      font-style: normal;
		}

		h4 {
			@apply font-bold mb-3;
		}

		a {
			@apply text-secondary underline;
		}
	}
}

.section-other-locations {
	.container {
		@apply py-10 flex flex-wrap flex-col;
	}

	h2 {
		@apply mx-auto;
	}

	.locations {
		@apply flex flex-nowrap gap-32;

		article {
			@apply flex flex-wrap flex-col;

			h3 {
        @apply text-2xl text-primary font-bold;
			}
      address {
        font-style: normal;
      }

      .btn {
        width: 8rem;
        padding-top: .5rem;
      }

			a:not(.btn) {
				@apply text-secondary;
			}
		}
	}
}

@media(max-width: theme('screens.tablet')) {
	.section-location-map {
		.container {
			@apply block;
		}

		.location__map {
			@apply mb-4;

			iframe {
				height: 250px;
			}
		}
	}

	.section-other-locations {
		.heading--decorated {
      &:after {
        @apply hidden;
      }
    }
    .locations {
			@apply flex-wrap gap-10;
		}
	}
}