.about_us-local-section-background {
  @apply bg-black;
  height: 750px;
  margin-top: var(--header-height);

  .container {
    @apply h-full flex flex-col justify-end pb-16;

    p {
      @apply font-medium text-xl mt-3 text-white;
    }
  }

  .hero__img {
    @apply absolute block top-0 left-0 w-full h-full z-0 object-cover opacity-60;
    max-height: 750px;
    margin-top: var(--header-height);
  }
}

.about_us-local-section-background {
  h1 {
    @apply text-secondary font-bold text-5xl tracking-tight;

    span {
      @apply block font-bold uppercase tracking-widest text-white text-lg;
    }
  }
}

.about--cards {
  @apply w-full m-0;

  .container {
    @apply grid grid-cols-2 p-0;

    h4, h2 span {
      @apply font-bold text-sm tracking-widest mb-2 uppercase;
    }

    h2, h3 {
      @apply text-3xl font-bold leading-normal;
    }

    p {
      @apply text-xl;
    }

    hr {
      @apply w-8 text-grey;
      height: 2px;
    }

    .about__text-card, .about__text-card-grey {
      @apply flex flex-col justify-center p-16;
    }

    .about__text-card-grey {
      @apply bg-grey;

      hr {
        @apply bg-gray-400;
      }
    }

    .about__img-card {
      display: flex;
    }

    .bg-img-1 {
      @apply bg-no-repeat bg-center bg-cover bg-primary;
      background-image: url("../images/about/about-section-1@2x.jpg");

    }

    .bg-img-2 {
      @apply bg-no-repeat bg-center bg-cover bg-secondary;
      background-image: url("../images/about/about-section-2@2x.jpg");
    }

    .overlay--primary {
      @apply w-full h-full bg-primary opacity-60;
    }

    .overlay--secondary {
      @apply w-full h-full bg-secondary opacity-60;
    }
  }
}

@media(max-width: theme('screens.desktop')) {
  .about_us-local-section-background {
    .container {
      h2 {
        @apply text-5xl;
      }

      p {
      }
    }
  }
}

@media(max-width: theme('screens.laptop')) {
  .about_us-local-section-background {
    .container {
      h2 {
        @apply text-4xl;
      }

      p {
      }
    }
  }
}


@media(max-width: theme('screens.tablet')) {
  .about_us-local-section-background {
    margin-top: var(--header-height--mobile);

    .container {
      h2 {
        font-size: 2.2rem;
      }

      p {
        @apply text-base;
      }
    }

    .hero__img {
      margin-top: var(--header-height--mobile);
    }
  }

  .about--cards {
    .container {
      @apply grid-cols-1 grid-flow-row;

      .about__img-card {
        height: 40vh;
      }

      .about__text-card {
        @apply p-8;
        order: -1;

        h4 {
          @apply text-xs;
        }

        h3 {
          @apply text-xl font-bold;
        }

        p {
          @apply text-sm;
        }
      }

      .about__text-card-grey {
        @apply p-8;

        h4 {
          @apply text-xs;
        }

        h3 {
          @apply text-xl;
        }

        p {
          @apply text-sm;
        }
      }
    }
  }
}

@media(max-width: theme('screens.mobile')) {
  .about_us-local-section-background {
    height: 550px;

    .container {
      @apply pb-6;
      @apply w-full;

      h2 {
        @apply text-3xl;
      }

      p {
        @apply text-base;
      }
    }

    .hero__img {
      max-height: 550px;
    }
  }
}