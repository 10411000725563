.header--site {
	@apply fixed top-0 left-0 w-full bg-white py-5 z-50 shadow-md;

	.header__wrap {
		@apply flex items-center px-6;
		.header__logo-container {
			@apply mr-0;
			.header__logo {
				@apply w-40;
			}
		}

		.header__hamburger {
			@apply laptop:hidden;
			
			.toggle-mobile-nav {
				span {
					@apply text-5xl;
				}
			}	
		}
		
	}
}

@media(max-width: theme('screens.laptop')) {
	.header--site {
		.header__wrap {
			.header__logo-container {
				@apply mr-auto;
			}

			.cash-offer {
				@apply mr-8;
			}
		}
	}
}

@media(max-width: theme('screens.tablet')) {
	.header--site {
		.header__wrap {
			.cash-offer {
				@apply hidden;
			}
		}
	}
}

.menu-toggle {
	@apply ml-auto w-40;
}