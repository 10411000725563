


// ACCORDION Core Function Styles
.accordion {
	width: 100%;
	.accordion__panel {
		@apply p-6 mb-3 rounded;
		border: solid 1px #ddd;
		
		
		.accordion__title {
			@apply flex flex-row text-secondary font-bold items-baseline;
			padding-top: .45rem;
			cursor: pointer;
			
			&:hover {
				@apply opacity-75;
			}
			
			&::after {
				@apply text-primary font-light text-lg ml-auto pl-6;
				content: "+";
				display: block;
			}
		}
		
		
		.accordion__body {
			@apply leading-tight pt-2;
			display: none;
			ul {
				li {
					@apply pl-3 mt-3;
					a {
						@apply text-primary font-semibold;
						&:hover { @apply opacity-75 underline; }
					}
				}
			}
		}
	}

	article.active {
		.accordion__title {
			&::after {
				content: "-";
			}
		}
	}
}

section.still-have-questions {
	@apply text-center py-20;
	.container {
		p {
			@apply text-base pt-8;
			a {
				@apply text-secondary underline;
				
				&:hover {
					@apply opacity-75;
				}
			}
		}
	}
}

@media(max-width: theme('screens.tablet')) {
	.accordion {
		.accordion__panel {
			.accordion__title {
				@apply text-base font-semibold;
				&:hover { @apply opacity-100; }
			}
			
			.accordion__body {
				ul {
					li {
						@apply text-base;
					}
				}
			}
		}
	}
	
	section.still-have-questions {
		.container {
			h1 {
				@apply text-2xl;
			}
			p {
				@apply text-base;
			}
		}
	}
}