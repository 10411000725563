.section--how-it-works {
	.container {
		@apply pt-28 pb-24;

    .heading__title {
      @apply mb-12 flex justify-center;
    }
	}

	.hiw-card-container {
		@apply grid grid-cols-3 gap-32 mb-10;

		.card {
			@apply flex flex-col items-center justify-start;

			.card__image {
				@apply py-6;

				img {
					height: 175px;
				}
			}

			h3 {
				@apply font-semibold py-2 text-3xl text-center;
			}
			hr {
				@apply w-8 my-2;
			}
			p {
				@apply font-light text-xl text-center;
			}
		}
		.arrows {
			@apply hidden;
		}
	}
}

@media(max-width: theme('screens.laptop')) {
	.section--how-it-works {
		.container {
			@apply py-16;
		}
		
		.hiw-card-container {
			@apply grid-cols-1 gap-10;
			.card {
				.card__image {
          @apply pt-0 pb-2;
        }
        
        h3 {
					@apply text-2xl;
				}
				p {
					@apply text-lg;
				}
        hr {
        }
			}
			.arrows {
				@apply flex justify-center h-10;
			}
		}
	}
}
@media(max-width: theme('screens.mobile')) {
	.section--how-it-works {
		
		.hiw-card-container {
			.card {
				.card__image {
          @apply w-1/2;
        }
        
        h3 {
          @apply pt-0;
				}
				p {
				}
        hr {
          @apply hidden;
        }
			}
			.arrows {
				@apply hidden;
			}
		}
	}
}