.section-offer-steps {
  @apply mb-16;

  .container {
    @apply grid grid-cols-3 gap-x-20;

    .offer__tp-container,
    .offer__accutrade-container {
      @apply col-span-2;
    }

    .offer__steps-container {
      @apply flex flex-col;
    }

    .offer__tp-container {
      @apply bg-gray-50 rounded-lg p-10;
      max-width: 900px;
    }

    #tp-widget {

      #tradepending-container {
        @apply h-full m-0 bg-none bg-transparent #{!important};
        max-width: 100% !important;

        #tradepending-panel > p,
        #tradepending-panel > h1 {
          @apply hidden #{!important};
        }

        #tradepending-panel {
          @apply text-left px-0 #{!important};


          #trade-pending-widget {
            input#tradepending-vehicle-typeahead {
              @apply border-2 border-gray-200;
            }
          }

          .tradepending-poweredby-container {
            @apply ml-auto mb-4;
          }

          p {
            @apply text-xl mt-8;
          }
        }
      }
    }

    .step {
      .step__number {
        @apply flex items-center font-medium uppercase text-primary text-sm mb-2;
        letter-spacing: 3px;

        .step__index {
          @apply inline-block rounded-full w-6 h-6 bg-primary text-white text-center p-1 pl-2 mr-3;
        }
      }

      .step__number--secondary {
        @apply text-secondary;

        .step__index {
          @apply bg-secondary;
        }
      }

      h2 {
        @apply text-3xl font-bold mb-2;
      }
    }

    .arrows {
      .arrow__img {
        @apply ml-12 mb-4;
        height: 2vw;
      }
    }
  }
}

@media(max-width: theme('screens.tablet')) {
  .section-offer-steps {
    .container {
      @apply flex flex-col;
      .offer__tp-container,
      .offer__accutrade-container {
      }

      .offer__steps-container {
        @apply order-first mx-auto mb-6;
      }

      .step {
        .step__number {
          .step__index {
          }
        }

        .step__number--secondary {

          .step__index {
          }
        }

        h2 {
          @apply text-xl;
        }
      }

      .arrows {
        .arrow__img {
          height: 1.25rem;
        }
      }
    }
  }
}

