.section-page-header-alt {
	.container {
		@apply flex flex-row justify-center content-center pb-16;
	}

	.faq__image-container {
		@apply mr-10;

		img {
			@apply h-28;
		}
	}

	h1 {
		@apply text-4xl font-semibold self-center;
	}
}

@media(max-width: theme('screens.tablet')) {
	.section-page-header-alt {
		.faq__image-container {
			@apply hidden;
		}
	}
}