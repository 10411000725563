.header--site {
	.header__wrap {
        
    nav {
      @apply mr-auto hidden laptop:block;
          
      ul {
        @apply flex font-normal text-base;
      
        li {
          @apply h-full relative ml-8;
        
          &:hover > a {
            @apply text-secondary underline;
          }
        }

        .current-page > a {
          @apply text-secondary font-medium;
        }
        li.dropdown {
					@apply relative pr-6;
					
          &::after {
						@apply absolute right-0 text-xl;
						top: -3px;
						font-family: 'Material Icons Round';
						content: "expand_more";
					}
					
					&:hover {
						& > .dropdown__content {
							@apply block;
						}
						&::after {
							@apply text-secondary;
						}
					}
					
					ul.dropdown__content {
						@apply hidden absolute shadow rounded border-black whitespace-nowrap bg-white pb-2;
						min-width: 200px;
						
						li {
							@apply inset-y-0 left-0 pt-4 ml-4 w-full;
						}
						li.current-page > a {
							@apply text-secondary font-medium;
						}
					}
				}
			}
		}
	}
}


@media(max-width: theme('screens.desktop')) {
	.header--site {
		.header__wrap {
      nav {
        ul {
          li {
            @apply ml-6;
          }
        } 
      }
    }   
	}
}