#tp-widget {
	#tradepending-container {
		#tradepending-panel {
			& > h1 {
				@apply text-4xl mb-5;
			}

			& > p {
				@apply text-lg mb-8;
			}

			#trade-pending-widget {
				@apply mb-3;

				#tradepending-vehicle-typeahead {
					@apply rounded-lg shadow-md py-6;
				}
			}
		}
	}
}

#tp-widget.tp-widget--how-it-works {
	#tradepending-container {
		@apply mb-0;

		#tradepending-panel {
			@apply mx-auto max-w-4xl bg-transparent;

			h2 {
				@apply text-5xl font-bold mb-8;
			}

			#trade-pending-widget {
				@apply w-full;

				#tradepending-input {
					@apply px-0;
				}
			}

			.tradepending-poweredby-container {
				@apply mt-4;
			}
		}
		div.tp-col {
			@apply block;
		}
	}
}